
import {Component, Ref, Vue} from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {
  ExternalProjectChangeApplyDto,
  AttachmentHostType,
  AuditFlowScope,
  DataDictionaryDto
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {AuditBodyDto} from "@/utils/customDto";

@Component({
  name: "ExternalProjectChangeApplyDetail",
  components: {
    AttachmentsView,
    AuditButtons
  }
})
export default class ExternalProjectChangeApplyDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  changeApplyTypeList: DataDictionaryDto[] = [];
  src1: string = "";
  src2: string = "";
  detail: ExternalProjectChangeApplyDto = {
    /**  */
    projectId: undefined,

    /**  */
    project: {},

    /**  */
    sourceExternalProjectId: undefined,

    /**  */
    sourceExternalProject: {},

    /**  */
    targetExternalProjectId: undefined,

    /**  */
    targetExternalProject: {},

    /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
    changeTypes: undefined,

    /** 变更时间 */
    changeDate: undefined,

    /** 变更原因 */
    changeReason: undefined,

    /** 变更内容 */
    changeContent: undefined,

    organizationUnit: {},

    /**  */
    id: undefined
  };
  hostType = AttachmentHostType.Internal_Personal_Year_End_Summary;
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ExternalProjectAdjustment,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  fetchAuditData() {
    return api.externalProjectChangeApply.audit;
  }

  changeAudit() {
    this.fetchDetail();
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    //
    api.dataDictionary
      .getDataDictionaryListByKey({key: "ProjectChangeType"})
      .then(res => {
        this.changeApplyTypeList = res.items!;
      });

    await api.externalProjectChangeApply.get({id: this.dataId}).then(res => {
      this.detail = {...res};
      this.auditParams!.hostId = res.id + "";
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      //
      this.src1 = '/#/project/externalProject/proposalDetailWithoutLayout/' + res.sourceExternalProjectId;
      this.src2 = '/#/project/externalProject/proposalDetailWithoutLayout/' + res.targetExternalProjectId;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit();
      }
    });
  }

  handleProjectDetail(id: any) {
    this.$router.push({
      name: "externalProjectProposal-detail",
      params: {
        id: id
      }
    });
  }

  formatChangeType(str: string) {
    const displayNames: string[] = [];
    this.changeApplyTypeList!.map((item: any) => {
      if (str.indexOf(item.key) > -1) {
        displayNames.push(item.displayName);
      }
    });
    return displayNames.join(",");
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
