
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import {
  ExternalProjectChangeApplyCreateOrUpdateDto,
  OrganizationUnitDto,
  DataDictionaryDto,
  AttachmentHostType
} from "@/api/appService";
import api from "@/api";
import { ElForm } from "element-ui/types/form";
import { UserModule } from "@/store/modules/user";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "ExternalProjectChangeApplyEdit",
  components: {
    ProjectSelect,
    MultipleUploadFile
  }
})
export default class ExternalProjectChangeApplyEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  targetId: number = 0;
  canView: boolean = false;
  canSelect: boolean = false;
  changeApplyTypeList: DataDictionaryDto[] = [];
  defaultData: ExternalProjectChangeApplyCreateOrUpdateDto = {
    /**  */
    projectId: undefined,

    /**  */
    sourceExternalProjectId: undefined,

    /**  */
    targetExternalProjectId: undefined,

    /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
    changeTypes: undefined,

    /** 变更时间 */
    changeDate: undefined,

    /** 变更原因 */
    changeReason: undefined,

    /** 变更内容 */
    changeContent: undefined,

    /**  */
    auditFlowId: undefined,

    /**  */
    audit: undefined,

    /**  */
    auditStatus: undefined,

    savedChangeTypes: [],

    /**  */
    //externalProject: undefined,

    /**  */
    id: 0
  };

  form: ExternalProjectChangeApplyCreateOrUpdateDto = {
    ...this.defaultData
  };

  get hostTypeFile() {
    return AttachmentHostType.Internal_Personal_Year_End_Summary;
  }

  created() {
    //
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProjectChangeType" })
      .then(res => {
        this.changeApplyTypeList = res.items!;
      });
    if (this.$route.params.targetId) {
      this.targetId = Number(this.$route.params.targetId);
      this.form.targetExternalProjectId = this.targetId;
      this.form.projectId = Number(this.$route.params.projectId);
      this.canView = true;
    }
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.canSelect = true;
      this.canView = true;
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectChangeApply.get({ id: this.dataId }).then(res => {
      this.form = { ...res };
      if (this.targetId > 0) {
        this.form.targetExternalProjectId = this.targetId;
      }
    });
  }

  getProjectDetail() {
    api.externalProject
      .getDetailByProjectId({ projectId: this.form.projectId })
      .then(res => {
        this.form.sourceExternalProjectId = res.id;
      });
  }

  editProject() {
    let that = this;
    if ((this.form.projectId ?? 0) == 0) {
      this.$message({
        type: "error",
        message: "请选择项目"
      });
      return;
    }

    if (this.form.id == 0 && (this.form.projectId ?? 0 > 0)) {
      that.$router.push({
        name: "externalProjectChangeApplyCreateProject",
        params: {
          sourceProjectId: (this.form.projectId ?? 0).toString()
        }
      });
    } else if (this.form.id ?? 0 > 0) {
      that.$router.push({
        name: "externalProjectChangeApplyEditProject",
        params: {
          targetExternalProjectId: (
            this.form.targetExternalProjectId ?? 0
          ).toString()
        }
      });
    }
  }

  cancel() {
    //this.$router.go(-1);
    this.$router.push({
      name: "externalProjectChangeApply"
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.externalProjectChangeApply.update({
            body: { ...this.form }
          });
        } else {
          await api.externalProjectChangeApply.create({
            body: { ...this.form }
          });
        }
        this.$message.success("更新成功");
        this.$router.push({
          name: "externalProjectChangeApply"
        });
        //this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "请检查表单"
        });
      }
    });
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "请选择项目名称",
        trigger: "blur"
      }
    ],
    savedChangeTypes: [
      {
        required: true,
        message: "请选择变更类型",
        trigger: "blur"
      }
    ],
    changeDate: [
      {
        required: true,
        message: "请选择变更时间",
        trigger: "blur"
      }
    ],
    changeReason: [
      {
        required: true,
        message: "请输入变更原因",
        trigger: "blur"
      }
    ],
    changeContent: [
      {
        required: true,
        message: "请输入变更内容",
        trigger: "blur"
      }
    ]
  };
}
